import {
  ABDLogoOfficial,
  Link,
  AuthButton,
  If,
  AuthButtonDisabled,
} from 'components'
import { useRouter } from 'next/router'
import { withClassNames } from '~/styles'
import s from './nav.module.scss'
import { useAuth } from '~/flow/AuthButton'
import { useEffect, useState } from 'react'
import { useFeatureFlag } from '../Lock/utils'
const cx = withClassNames(s)

export const Nav = () => {
  const router = useRouter()
  const [menuOpen, setMenuOpen] = useState(false)
  const featureFlag = useFeatureFlag()

  useEffect(() => {
    if (menuOpen)
      return document.querySelector('body')?.classList.add('lock-scroll')
    document.querySelector('body')?.classList.remove('lock-scroll')
  }, [menuOpen])

  const toggleMobileNav = () => {
    if (window.innerWidth > 830) return
    setMenuOpen(!menuOpen)
  }

  return (
    <nav
      className={cx(s.navbar, {
        [cx('is-active')]: menuOpen,
      })}
    >
      <div className={cx('container', 'nav-container')}>
        <div
          onClick={toggleMobileNav}
          className={cx('menu', {
            [cx('is-active')]: menuOpen,
          })}
        >
          <span></span>
        </div>
        <Link
          onClick={() => setMenuOpen(false)}
          className={cx('abd-logo')}
          href='/'
        >
          <ABDLogoOfficial />
        </Link>
        <div className={cx('link-container')}>
          <MyCollectionLink onClick={toggleMobileNav} />
          <Link
            onClick={toggleMobileNav}
            className={cx({ [cx('is-active')]: router.pathname === '/packs' })}
            href='https://store.abdcollectibles.com/collections/packs'
          >
            Packs
          </Link>
          <Link
            onClick={toggleMobileNav}
            className={cx({ [cx('is-active')]: router.pathname === '/collectibles' })}
            href='https://store.abdcollectibles.com/collections/collectibles'
          >
            Cards
          </Link>
          <Link
            onClick={toggleMobileNav}
            className={cx({ [cx('is-active')]: router.pathname === '/auctions' })}
            href='https://store.abdcollectibles.com/collections/auctions'
          >
            Auctions
          </Link>
          <Link onClick={toggleMobileNav} 
            className={cx({ [cx('is-active')]: router.pathname === '/marketplace' })}
            href='/marketplace'>
            Marketplace
          </Link>
          <Link
            onClick={toggleMobileNav}
            href='https://store.abdcollectibles.com/collections/accessories'
          >
            Accessories
          </Link>
          <Link onClick={toggleMobileNav} 
            className={cx({ [cx('is-active')]: router.pathname === '/contact-us' })}
            href='/contact-us'>
            Contact Us
          </Link>
        </div>
        <div className={cx('button-nav-mobile')}>
          <If cond={!featureFlag.yrDisablePacks}>
            <AuthButton />
          </If>
          <If cond={featureFlag.yrDisablePacks}>
            <AuthButtonDisabled />
          </If>
        </div>
      </div>
    </nav>
  )
}

const MyCollectionLink = ({ onClick }: { onClick: () => void }) => {
  const { currentUser } = useAuth()
  const router = useRouter()

  if (!currentUser?.loggedIn) return null

  return (
    <Link
      onClick={onClick}
      href='/my-collection'
      className={cx({
        [cx('is-active')]: ['/my-collection', '/unopenedPacks'].includes(
          router.pathname,
        ),
      })}
    >
      My Collection
    </Link>
  )
}